import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '255, 255, 255',
		'primary-dark': '242, 242, 245',
		'accent': '234, 122, 3',
		'accent-plus': '0, 0, 0',
	},
});
